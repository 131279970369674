import oneBox from "@drift/oneplatfront";
import packageInfo from "../package.json";


const appId = "PORTAL";

oneBox
    .run({
        settings: {
            one: {
                login: {
                    target: {
                        params: ["mode=redirect", `appId=${appId}`],
                    },
                },
                box: {
                    module: {
                        name: "PORTAL",
                        version: packageInfo.version,
                    },
                    powerboard: {
                        show: true
                    }
                },
                layout: { title: "PORTAL" },
            },
        },
        features: [],
        services: [],
    })
    .catch(console.error);
